<template>
  <div id="PaySuccess">
    <div class="main">
      <div>
        <div class="head">
          <van-nav-bar
            title="等待"
            style="background-color: rgba(255,255,255,0);"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <img src="@/assets/images/wait.png">
        <div class="title">支付中</div>
        <div class="des">订单状态将在2-10分钟内更新，请耐心等待。</div>
        <button @click="jumpToHome">返回</button>
      </div>
    </div>
  </div>
</template>
<script>
import { queryOrderById } from '@/api/orderlist'
export default {
  name: 'PaySuccess',
  data() {
    return {
      time: null
      // num: 5
    }
  },
  mounted() {
    this.countDown()
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #fff')
  },
  destroyed() {
    if (this.time) { // 如果定时器在运行则关闭
      clearInterval(this.time)
    }
  },
  methods: {
    jumpToHome() {
      if (this.$route.query.returnUrl !== null && this.$route.query.returnUrl !== '' && this.$route.query.returnUrl !== undefined) {
        // console.log(this.$route.query.returnUrl)
        window.location.href = this.$route.query.returnUrl
      } else {
        this.$router.push({ path: '/orderList' })
      }
    },
    countDown() {
      // if (this.$route.query.returnUrl) {
      //   this.time = setInterval(() => {
      //     // console.log('定时已启动...')
      //     // // getOrderById(this.$route.query.orderId).then(res => {
      //     // //   if (res.data.status === 'COMPLETED') {
      //     // //     window.location.href = this.$route.query.returnUrl
      //     // //   }
      //     // // })
      //   }, 5000)
      // }
      this.time = setInterval(() => {
        queryOrderById(this.$route.query.orderId).then(res => {
          if (res.data.returnUrl) {
            window.location.href = res.data.returnUrl
          } else {
            if (this.$route.query.returnUrl !== null && this.$route.query.returnUrl !== '' && this.$route.query.returnUrl !== undefined) {
              // console.log(this.$route.query.returnUrl)
              window.location.href = this.$route.query.returnUrl
            } else {
              this.$router.push({ path: '/orderList' })
            }
          }
        })
      }, 5000)
    }
  }
}
</script>
<style scoped lang="scss">
#PaySuccess {
  .head {
    height: 84px;
    width: 100%;
    background: linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  // padding: 0px 10px 10px 10px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin: 35px 0 32px;
    width: 60%;
  }
  .title {
    font-size: 24px;
    color: rgba(82, 212, 167, 1);
    margin-bottom: 15px;
  }
  .des {
    font-size: 14px;
    color: rgba(116, 116, 116, 1);
  }
  button {
    position: fixed;
    bottom: 35px;
    width: 330px;
    height: 44px;
    border-radius: 4px;
    border: 1px solid rgba(47, 159, 242, 1);
    background-color: rgba(47, 159, 242, 1);
    color: #fff;
    font-size: 14px;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .van-nav-bar .van-icon {
  color: #ffffff;
}
::v-deep .van-nav-bar__title {
  color: #ffffff;
}
::v-deep .van-nav-bar__text {
  color: #ffffff;
}
[class*="van-hairline"]::after {
  border: none;
}
::v-deep .van-nav-bar {
  height: 80px;
}
</style>
